import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';
import {
  AddNotification,
  AssignSelectedGroups,
  AssignSelectedInstances,
  AssignSelectedInstancesForKpaUser,
  AssignSelectedRoles,
  ChangeDescriptionForNewGroup,
  ChangeFirstNameFieldFromEditUserDrawer,
  ChangeLastNameFieldFromEditUserDrawer,
  ChangeNameForNewGroup,
  ClearFetchingUserRolesIds,
  ClearInitialUsersStepperState,
  ClearPersistUsersStepperState,
  ClearUserDetails,
  ClearUsers,
  CreateAllUsers,
  CreateGroup,
  DeleteAssignedGroup,
  DeleteAssignedRole,
  DiscardChanges,
  DiscardInviteChanges,
  EditUser,
  EditUserDetails,
  FetchAllGroups,
  FetchAllRoles,
  FetchAllUserRolesAssignments,
  FetchAssignedGroups,
  FetchAssignedRoles,
  FetchEditedUser,
  FetchGroupsToRolesRelations,
  HideEditUserDetailsDrawer,
  HideErrorInviteUsersNotification,
  HideIncompleteUserDataModal,
  HideNotification,
  HideSuccessCreatedGroupNotification,
  HideSuccessInviteUsersNotification,
  SetActiveRemoveGroupId,
  SetActiveRemoveRoleId,
  SetActiveRemoveRoleWithInstanceRowData,
  SetAddUsersStep,
  SetAssignedRolesTablePagination,
  SetAssignedRolesTableSearch,
  SetAssignedRolesTableSorting,
  SetAssignRolesTable,
  SetAssignRolesTableDrawer,
  SetAssignRolesWithInstancesTableDrawer,
  SetAssignRolesWithInstancesTableStepper,
  SetGroupsOptionsFromDrawer,
  SetGroupsTablePagination,
  SetGroupsTableSearch,
  SetGroupsTableSorting,
  SetInviteDirty,
  SetInviteStepperPendingToCreateUsers,
  SetInviteStepperStep,
  SetInviteUsers,
  SetInviteUsersGroupsOptions,
  SetIsPending,
  SetIsUsersFileUploaded,
  SetNewGroupName,
  SetPristineRolesOptionsFromDrawer,
  SetProductFilterOptions,
  SetProductFilterSelectedOption,
  SetProductOptionsFromDrawer,
  SetProductsOptions,
  SetRegionFilterOptions,
  SetRegionFilterSelectedOption,
  SetRolesOptions,
  SetRolesOptionsFromDrawer,
  SetSecurityCloudControlRole,
  SetSecurityCloudControlRoleDrawer,
  SetStepperInviteUsersMethod,
  SetTypesFilterOptions,
  SetTypesFilterSelectedOption,
  SetUploadCSVName,
  SetUploadCSVProgress,
  SetUserError,
  SetUserIsLoading,
  SetUsersTableActiveTab,
  ShowEditUserDetailsDrawer,
  ShowErrorInviteUsersNotification,
  ShowIncompleteUserDataModal,
  ShowSuccessCreatedGroupNotification,
  ShowSuccessInviteUsersNotification,
  ToggleAssignGroupsDrawerVisibility,
  ToggleAssignRolesDrawerVisibility,
  ToggleDiscardChangesModal,
  ToggleGroupsDrawerVisibility,
  ToggleInviteStepperCreateUsersNotification,
  SetUserSearchValue,
} from './actions';
import {
  ErrorMessagesType,
  UserStepperStates,
} from '../../enums/AddUsersStepper';
import { RolesPermission } from '../../enums/RolesPermissionList';
import { TabsList } from '../../enums/TabsList';
import { UserStatus } from '../../enums/UserStatus';
import { IUsers } from '../../interfaces/IUsers';
import { TABLE_CONFIG } from '../../utils/constant';

export const initialState: IUsers = {
  usersTableActiveTab: TabsList.CurrentUsers,
  userSearchValue: '',
  activeEditUserId: null,
  allRoles: [],
  allGroups: [],
  userDetails: {
    id: '',
    isAdmin: false,
    status: UserStatus.ENTERPRISE_DISABLED,
    profile: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      login: '',
    },
  },
  editUserDetailsDrawerFields: {
    firstName: {
      value: '',
      error: true,
      touched: false,
      focused: false,
      errorMessage: 'users:firstNameFieldRequiredError',
    },
    lastName: {
      value: '',
      error: true,
      touched: false,
      focused: false,
      errorMessage: 'users:lastNameFieldRequiredError',
    },
  },
  editUser: {
    roles: [],
    groups: [],
    productOptionsFromDrawer: [],
    rolesOptionsFromDrawer: [],
    groupsOptionsFromDrawer: [],
    pristineRolesOptionsFromDrawer: [],
    activeRemoveRoleId: null,
    activeRemoveRoleWithInstanceRowData: null,
    activeRemoveGroupId: null,
    isAssignRolesDrawerVisible: false,
    isAssignGroupsDrawerVisible: false,
    assignRolesDrawer: {
      sccRole: RolesPermission.MEMBER,
      assignRolesTable: [
        {
          selectedProduct: null,
          productsOptions: [],
          rolesOptions: {},
        },
      ],
      assignRolesWithInstancesTable: [
        {
          selectedRole: null,
          rolesOptions: [],
          instancesOptions: {},
        },
      ],
    },
    assignedRolesTablePagination: {
      pageSize: TABLE_CONFIG.paginationConfig.pageSize,
      pageIndex: 0,
    },
    assignRolesTable: {
      search: '',
      productFilterOptions: [],
      productFilterSelectedOption: null,
      regionFilterOptions: [],
      regionFilterSelectedOption: null,
      typesFilterOptions: [],
      typesFilterSelectedOption: null,
      sortingOptions: null,
    },
    groupsTablePagination: {
      pageSize: TABLE_CONFIG.paginationConfig.pageSize,
      pageIndex: 0,
    },
    groupsTable: {
      search: '',
      sortingOptions: null,
    },
  },
  groupsToRolesRelations: {
    items: [],
    loading: false,
    error: null,
  },
  // invite stepper
  inviteStepper: {
    inviteUsersMethod: undefined, // This info using for show invite User error message.
    isPendingToCreateUsers: false,
    createUsersNotification: false,
    currentStep: 1,
    createUsersResponse: {
      invitedUsersCount: 0,
      errors: null,
    },
    isSuccessInviteUsersNotificationVisible: false,
    isUserDetailsDirty: false,
    newGroup: {
      name: {
        value: '',
        touched: false,
        focused: false,
        errorMessage: ErrorMessagesType.REQUIRED,
      },
      description: {
        value: '',
      },
    },
  },
  // -- TODO: Move to inviteUsers stepper object
  usersStepperStep: UserStepperStates.invite,
  uploadFile: {
    fileName: '',
    progressBar: 0,
  },
  newGroupName: '',
  groupsOptions: [],
  sccRole: RolesPermission.MEMBER,
  productsOptions: [],
  rolesOptions: {},
  assignRolesTable: [
    {
      selectedProduct: null,
      productsOptions: [],
      rolesOptions: {},
    },
  ],
  assignRolesWithInstancesTable: [
    {
      selectedRole: null,
      rolesOptions: [],
      instancesOptions: {},
    },
  ],
  inviteUsers: [],
  isUsersFileUploaded: false,
  isGroupDrawerVisible: false,
  isIncompleteUserDataModalVisible: false,
  isSuccessCreatedGroupNotificationVisible: false,
  // invite stepper
  isUserDetailsDrawerVisible: false,
  isErrorInviteUsersNotificationVisible: false,
  isDiscardChangesModalVisible: false,
  isCreateGroupLoading: false,
  notificationType: null,
  assignedRolesByUserId: {},
  assignedGroupRolesByUserId: {},
  fetchingUserDataIds: [],
  error: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [SetUsersTableActiveTab.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    usersTableActiveTab: payload,
  }),

  [FetchAllGroups.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),
  [FetchAllGroups.Success.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    allGroups: payload,
    isLoading: false,
  }),
  [FetchAllGroups.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    groupsOptions: [],
    isLoading: false,
    error: payload,
  }),

  [FetchAssignedGroups.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),
  [FetchAssignedGroups.Success.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      groups: payload,
    },
    isLoading: false,
  }),
  [FetchAssignedGroups.Error.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [SetActiveRemoveRoleId.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      activeRemoveRoleId: payload,
    },
  }),

  [SetActiveRemoveRoleWithInstanceRowData.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      activeRemoveRoleWithInstanceRowData: payload,
    },
  }),

  [SetActiveRemoveGroupId.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      activeRemoveGroupId: payload,
    },
  }),

  [SetRolesOptionsFromDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      rolesOptionsFromDrawer: payload,
    },
  }),

  [SetProductOptionsFromDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      productOptionsFromDrawer: payload,
    },
  }),

  [SetPristineRolesOptionsFromDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      pristineRolesOptionsFromDrawer: payload,
    },
  }),

  [SetGroupsOptionsFromDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      groupsOptionsFromDrawer: payload,
    },
  }),

  [EditUser.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    activeEditUserId: payload,
  }),

  [SetInviteUsersGroupsOptions.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    groupsOptions: payload,
  }),

  [SetIsUsersFileUploaded.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isUsersFileUploaded: payload,
  }),
  [SetInviteUsers.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    inviteUsers: payload,
  }),
  [SetNewGroupName.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    newGroupName: payload,
  }),
  [SetSecurityCloudControlRole.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    sccRole: payload,
  }),
  [SetProductsOptions.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    productsOptions: payload,
  }),
  [SetRolesOptions.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    rolesOptions: payload,
  }),
  [SetAssignRolesTable.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    assignRolesTable: payload,
  }),
  [SetAssignRolesWithInstancesTableStepper.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    assignRolesWithInstancesTable: payload,
  }),

  [SetAddUsersStep.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      currentStep: payload,
    },
  }),

  [ToggleGroupsDrawerVisibility.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isGroupDrawerVisible: payload,
    inviteStepper: {
      ...state.inviteStepper,
      newGroup: {
        name: {
          value: '',
          touched: false,
          focused: false,
          errorMessage: ErrorMessagesType.REQUIRED,
        },
        description: {
          value: '',
        },
      },
    },
  }),

  [ToggleDiscardChangesModal.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isDiscardChangesModalVisible: payload,
  }),

  [DiscardInviteChanges.TYPE]: (state: IUsers) => ({
    ...state,
    inviteStepper: initialState.inviteStepper,
    assignRolesWithInstancesTable: [
      {
        selectedRole: null,
        rolesOptions: [],
        instancesOptions: {},
      },
    ],
    isDiscardChangesModalVisible: false,
  }),

  [ShowIncompleteUserDataModal.TYPE]: (state: IUsers) => ({
    ...state,
    isIncompleteUserDataModalVisible: true,
  }),
  [HideIncompleteUserDataModal.TYPE]: (state: IUsers) => ({
    ...state,
    isIncompleteUserDataModalVisible: false,
  }),

  [ShowSuccessCreatedGroupNotification.TYPE]: (state: IUsers) => ({
    ...state,
    isSuccessCreatedGroupNotificationVisible: true,
  }),
  [HideSuccessCreatedGroupNotification.TYPE]: (state: IUsers) => ({
    ...state,
    isSuccessCreatedGroupNotificationVisible: false,
  }),

  [ShowErrorInviteUsersNotification.TYPE]: (state: IUsers) => ({
    ...state,
    isErrorInviteUsersNotificationVisible: true,
  }),
  [HideErrorInviteUsersNotification.TYPE]: (state: IUsers) => ({
    ...state,
    isErrorInviteUsersNotificationVisible: false,
  }),

  [CreateGroup.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
    isCreateGroupLoading: true,
  }),
  [CreateGroup.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
    isCreateGroupLoading: false,
  }),
  [CreateGroup.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    isCreateGroupLoading: false,
    newGroupName: '',
    error: payload,
  }),

  [FetchAllRoles.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),
  [FetchAllRoles.Success.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    allRoles: payload,
    isLoading: false,
  }),
  [FetchAllRoles.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    productsOptions: [],
    rolesOptions: {},
    error: payload,
  }),

  [AssignSelectedGroups.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),

  [AssignSelectedGroups.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),

  [AssignSelectedGroups.Error.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [AssignSelectedRoles.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),

  [AssignSelectedRoles.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),

  [AssignSelectedRoles.Error.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [AssignSelectedInstances.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),

  [AssignSelectedInstances.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),
  [AssignSelectedInstancesForKpaUser.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),

  [AssignSelectedInstancesForKpaUser.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),

  [DeleteAssignedGroup.Pending.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isLoading: true,
    deleteGroupId: payload,
  }),
  [DeleteAssignedGroup.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),
  [DeleteAssignedGroup.Error.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      activeRemoveGroupId: null,
    },
    isLoading: false,
    error: payload,
  }),

  [FetchAssignedRoles.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),
  [FetchAssignedRoles.Success.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      roles: payload,
    },
    isLoading: false,
  }),
  [FetchAssignedRoles.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [FetchAllUserRolesAssignments.Pending.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => {
    const userId = payload;

    const isAlreadyFetching = state.fetchingUserDataIds.includes(userId);

    return {
      ...state,
      fetchingUserDataIds: isAlreadyFetching
        ? state.fetchingUserDataIds
        : [...state.fetchingUserDataIds, userId],
    };
  },

  [FetchAllUserRolesAssignments.Success.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => {
    const { groupsWithRoles, userId, assignedRoles } = payload;

    return {
      ...state,
      assignedRolesByUserId: {
        ...state.assignedRolesByUserId,
        [userId]: assignedRoles,
      },
      assignedGroupRolesByUserId: {
        ...state.assignedGroupRolesByUserId,
        [userId]: groupsWithRoles || [],
      },
      fetchingUserDataIds: state.fetchingUserDataIds.filter(
        (id) => id !== userId
      ),
    };
  },

  [FetchAllUserRolesAssignments.Error.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => {
    const { userId } = payload;

    return {
      ...state,
      fetchingUserDataIds: state.fetchingUserDataIds.filter(
        (id) => id !== userId
      ),
    };
  },

  [ClearFetchingUserRolesIds.TYPE]: (state: IUsers) => {
    return {
      ...state,
      fetchingUserDataIds: [],
    };
  },

  [DeleteAssignedRole.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),
  [DeleteAssignedRole.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),
  [DeleteAssignedRole.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      activeRemoveRoleId: null,
      activeRemoveRoleWithInstanceRowData: null,
    },
    isLoading: false,
    error: payload,
  }),

  [SetAssignRolesTableDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesDrawer: {
        ...state.editUser.assignRolesDrawer,
        assignRolesTable: payload,
      },
    },
  }),

  [SetAssignRolesWithInstancesTableDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesDrawer: {
        ...state.editUser.assignRolesDrawer,
        assignRolesWithInstancesTable: payload,
      },
    },
  }),

  [SetSecurityCloudControlRoleDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesDrawer: {
        ...state.editUser.assignRolesDrawer,
        sccRole: payload,
      },
    },
  }),

  [FetchEditedUser.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),

  [FetchEditedUser.Success.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    userDetails: payload,
  }),

  [FetchEditedUser.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [ClearUserDetails.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
    userDetails: null,
  }),

  [EditUserDetails.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),

  [EditUserDetails.Success.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: false,
  }),

  [EditUserDetails.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [FetchGroupsToRolesRelations.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    groupsToRolesRelations: {
      ...state.groupsToRolesRelations,
      isLoading: true,
      error: null,
    },
  }),

  [FetchGroupsToRolesRelations.Success.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    groupsToRolesRelations: {
      ...state.groupsToRolesRelations,
      items: payload,
      isLoading: false,
    },
  }),

  [FetchGroupsToRolesRelations.Error.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),

  [ChangeFirstNameFieldFromEditUserDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUserDetailsDrawerFields: {
      ...state.editUserDetailsDrawerFields,
      firstName: {
        ...state.editUserDetailsDrawerFields.firstName,
        ...payload,
      },
    },
  }),

  [ChangeLastNameFieldFromEditUserDrawer.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUserDetailsDrawerFields: {
      ...state.editUserDetailsDrawerFields,
      lastName: {
        ...state.editUserDetailsDrawerFields.lastName,
        ...payload,
      },
    },
  }),

  [ShowEditUserDetailsDrawer.TYPE]: (state: IUsers) => ({
    ...state,
    isUserDetailsDrawerVisible: true,
    editUser: {
      ...state.editUser,
      isAssignRolesDrawerVisible: false,
      isAssignGroupsDrawerVisible: false,
    },
  }),
  [HideEditUserDetailsDrawer.TYPE]: (state: IUsers) => ({
    ...state,
    isUserDetailsDrawerVisible: false,
    editUserDetailsDrawerFields: initialState.editUserDetailsDrawerFields,
  }),

  [ToggleAssignRolesDrawerVisibility.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      isAssignRolesDrawerVisible: payload,
    },
  }),

  [ToggleAssignGroupsDrawerVisibility.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      isAssignGroupsDrawerVisible: payload,
    },
  }),

  [DiscardChanges.TYPE]: () => initialState,

  [AddNotification.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    notificationType: payload,
  }),
  [HideNotification.TYPE]: (state: IUsers) => ({
    ...state,
    notificationType: null,
  }),

  [SetUploadCSVName.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    uploadFile: {
      ...state.uploadFile,
      fileName: payload,
    },
  }),
  [SetUploadCSVProgress.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    uploadFile: {
      ...state.uploadFile,
      progressBar: payload,
    },
  }),

  [SetUserIsLoading.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: payload,
  }),
  [SetUserError.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    error: payload,
  }),
  [SetProductFilterOptions.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        productFilterOptions: payload,
      },
    },
  }),
  [SetProductFilterSelectedOption.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        productFilterSelectedOption: payload,
      },
      assignedRolesTablePagination: {
        ...state.editUser.assignedRolesTablePagination,
        pageIndex: 0,
      },
    },
  }),
  [SetRegionFilterOptions.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        regionFilterOptions: payload,
      },
    },
  }),
  [SetRegionFilterSelectedOption.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        regionFilterSelectedOption: payload,
      },
      assignedRolesTablePagination: {
        ...state.editUser.assignedRolesTablePagination,
        pageIndex: 0,
      },
    },
  }),
  [SetTypesFilterOptions.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        typesFilterOptions: payload,
      },
    },
  }),
  [SetTypesFilterSelectedOption.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        typesFilterSelectedOption: payload,
      },
      assignedRolesTablePagination: {
        ...state.editUser.assignedRolesTablePagination,
        pageIndex: 0,
      },
    },
  }),
  [SetAssignedRolesTablePagination.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignedRolesTablePagination: payload,
    },
  }),
  [SetGroupsTablePagination.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      groupsTablePagination: payload,
    },
  }),
  [SetAssignedRolesTableSearch.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        search: payload,
      },
    },
  }),
  [SetGroupsTableSearch.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      groupsTable: {
        ...state.editUser.groupsTable,
        search: payload,
      },
    },
  }),
  [SetAssignedRolesTableSorting.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    editUser: {
      ...state.editUser,
      assignRolesTable: {
        ...state.editUser.assignRolesTable,
        sortingOptions: payload,
      },
    },
  }),
  [SetGroupsTableSorting.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    editUser: {
      ...state.editUser,
      groupsTable: {
        ...state.editUser.groupsTable,
        sortingOptions: payload,
      },
    },
  }),

  /* ----------------- Invite Stepper ----------------- */
  [SetInviteStepperStep.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    usersStepperStep: payload,
  }),
  [CreateAllUsers.Pending.TYPE]: (state: IUsers) => ({
    ...state,
    isLoading: true,
  }),
  [CreateAllUsers.Success.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    inviteStepper: {
      ...state.inviteStepper,
      createUsersResponse: {
        ...state.inviteStepper.createUsersResponse,
        ...payload,
      },
    },
  }),
  [CreateAllUsers.Error.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    inviteStepper: {
      ...state.inviteStepper,
      createUsersResponse: {
        ...state.inviteStepper.createUsersResponse,
        ...payload,
      },
    },
  }),
  [SetInviteStepperPendingToCreateUsers.TYPE]: (state: IUsers) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      isPendingToCreateUsers: true,
    },
  }),
  [SetIsPending.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      isPendingToCreateUsers: payload,
    },
  }),
  [ToggleInviteStepperCreateUsersNotification.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      createUsersNotification: payload,
    },
  }),
  [ShowSuccessInviteUsersNotification.TYPE]: (state: IUsers) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      isPendingToCreateUsers: false,
      isSuccessInviteUsersNotificationVisible: true,
    },
  }),
  [HideSuccessInviteUsersNotification.TYPE]: (state: IUsers) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      isPendingToCreateUsers: false,
      isSuccessInviteUsersNotificationVisible: false,
    },
  }),

  [ChangeNameForNewGroup.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      newGroup: {
        ...state.inviteStepper.newGroup,
        name: {
          ...state.inviteStepper.newGroup.name,
          ...payload,
        },
      },
    },
  }),
  [ChangeDescriptionForNewGroup.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      newGroup: {
        ...state.inviteStepper.newGroup,
        description: {
          ...state.inviteStepper.newGroup.description,
          ...payload,
        },
      },
    },
  }),

  [SetStepperInviteUsersMethod.TYPE]: (
    state: IUsers,
    { payload }: AnyAction
  ) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      inviteUsersMethod: payload,
    },
  }),

  [SetInviteDirty.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      isUserDetailsDirty: payload,
    },
  }),

  [ClearPersistUsersStepperState.TYPE]: (state: IUsers) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      isPendingToCreateUsers: false,
    },
    inviteUsers: [],
    groupsOptions: [],
    productsOptions: [],
    rolesOptions: {},
    sccRole: RolesPermission.MEMBER,
    assignRolesTable: [
      {
        selectedProduct: null,
        productsOptions: [],
        rolesOptions: {},
      },
    ],
  }),
  [ClearInitialUsersStepperState.TYPE]: (state: IUsers) => ({
    ...state,
    inviteStepper: {
      ...state.inviteStepper,
      createUsersNotification: false,
      createUsersResponse: {
        invitedUsersCount: 0,
        errors: null,
      },
    },
    uploadFile: {
      fileName: '',
      progressBar: 0,
    },
    newGroupName: '',
    isUsersFileUploaded: false,
    isGroupDrawerVisible: false,
    isIncompleteUserDataModalVisible: false,
    isSuccessCreatedGroupNotificationVisible: false,
    isDiscardChangesModalVisible: false,
    error: null,
  }),

  [ClearUsers.TYPE]: () => ({
    ...initialState,
  }),

  [SetUserSearchValue.TYPE]: (state: IUsers, { payload }: AnyAction) => ({
    ...state,
    userSearchValue: payload,
  }),
});
