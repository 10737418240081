import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import {
  find,
  isEmpty,
  reduce,
  uniqBy,
  capitalize,
  uniq,
  intersection,
  groupBy,
} from 'lodash';
import { createSelector } from 'reselect';
import { UserRoleType } from 'src/app/enums/UserRoles';
import { IRbacUserRole } from 'src/app/interfaces/IUserRoles';
import { sliceTableData } from './helper';
import {
  IRbacAssignedRole,
  IRbacGroup,
  IGroupToRoleRelation,
  IRbacTableDataGroup,
  IRbacTableDataRole,
  IDependentOption,
  IAssigneRoleRow,
  IAssignedRole,
} from './interfaces';
import {
  removeProductsOptionsWithoutUnassignedRoles,
  removeUserRolesFromRolesOptions,
} from './utilities';
import { IState } from '../../core/redux/interfaces';
import { EnterpriseRegionType } from '../../enums/EnterpriseType';
import { ProvisioningStatus } from '../../enums/ProvisioningStatus';
import { RolesPermission } from '../../enums/RolesPermissionList';
import {
  IUser,
  IProductInstance,
  IProductInstanceOption,
} from '../../interfaces/ICommon';
import {
  IAssignedRoleWIthInstanceTableRow,
  IInstanceOption,
  IRolesWithInstances,
  IUsers,
} from '../../interfaces/IUsers';
import {
  FEATURE_FLAG_CONSTANTS,
  ENTERPRISE,
  ENTERPRISE_PRODUCT_TYPE,
  ENTERPRISE_PRODUCT_NAME,
} from '../../utils/constant';
import {
  getTablePaginationPagesCount,
  composeFunctions,
  sortTableData,
} from '../../utils/shared';
import {
  classifyGroupsForKpa,
  convertRolesToInstanceDictionary,
  filterRolesByExistingProducts,
  getProductInstanceDisplayName,
  getProvisionedProductInstances,
} from '../common/helpers';
import {
  getAllUsers,
  getAllProductsInstances,
  getCurrentUserId,
  hasFeatureFlags,
  getAllProvisionedProductsInstances,
} from '../common/selectors';

export interface IOption {
  label: string;
  value: string;
  selected?: boolean;
}

export const getState = (state: IState): IUsers => state.users || {};

export const getUsersTableActiveTab = createSelector(
  [getState],
  ({ usersTableActiveTab }) => usersTableActiveTab
);

export const getUserDetails = createSelector(
  [getState],
  ({ userDetails }) => userDetails
);

export const getEditUserDetails = createSelector(
  [getState],
  ({ userDetails }) => userDetails
);

export const getCurrentEditUserId = createSelector(
  [getEditUserDetails],
  ({ id }) => id
);

export const getEditUserDetailsDrawerFields = createSelector(
  [getState],
  ({ editUserDetailsDrawerFields }) => editUserDetailsDrawerFields
);

export const getIsUserDetailsDrawerVisible = createSelector(
  [getState],
  ({ isUserDetailsDrawerVisible }) => isUserDetailsDrawerVisible
);

export const getIsLoading = createSelector(
  [getState],
  ({ isLoading }) => isLoading
);

export const getIsCreateGroupLoading = createSelector(
  [getState],
  ({ isCreateGroupLoading }) => isCreateGroupLoading
);

export const getActiveEditUserId = createSelector(
  [getState],
  ({ activeEditUserId }) => activeEditUserId
);

export const getIsUsersFileUploaded = createSelector(
  [getState],
  ({ isUsersFileUploaded }) => isUsersFileUploaded
);

export const getInviteUsers = createSelector(
  [getState],
  ({ inviteUsers }) => inviteUsers
);

export const getNewGroupName = createSelector(
  [getState],
  ({ newGroupName }) => newGroupName
);

export const getGroupsOptions = createSelector(
  [getState],
  ({ groupsOptions }) => groupsOptions
);

export const getSelectedGroupsIDs = createSelector(
  [getState],
  ({ groupsOptions }) =>
    groupsOptions
      .filter((item: IOption) => item.selected)
      .map((item: IOption) => item.value)
);

export const getPristineGroupsOptions = createSelector(
  [getState],
  ({ groupsOptions }) =>
    groupsOptions.map((option: IOption) => ({ ...option, selected: false }))
);

export const getProductsOptions = createSelector(
  [getState],
  ({ productsOptions }) => productsOptions
);

export const getSCCRole = createSelector([getState], ({ sccRole }) => sccRole);

export const getRolesOptions = createSelector(
  [getState],
  ({ rolesOptions }) => rolesOptions
);

export const getSelectedProducts = createSelector(
  [getState],
  ({ assignRolesTable }) =>
    assignRolesTable.map((row) => row.selectedProduct).filter(Boolean)
);

export const getSelectedRolesIDs = createSelector(
  [getState],
  ({ assignRolesTable }) => {
    const selectedRolesValues: string[] = [];

    for (const row of assignRolesTable) {
      if (row.selectedProduct) {
        const selectedProductRoles = row.rolesOptions[row.selectedProduct];
        const rolesIDs = selectedProductRoles
          .filter((role: IOption) => role.selected)
          .map((role: IOption) => role.value);
        selectedRolesValues.push(...rolesIDs);
      }
    }

    return selectedRolesValues;
  }
);

export const getSelectedRolesIDsWithInstances = createSelector(
  [getState],
  ({ assignRolesWithInstancesTable }) => {
    return assignRolesWithInstancesTable.reduce((acc: any, row) => {
      if (!row.selectedRole) return acc;
      const instanceOptions = Object.values(row.instancesOptions);
      instanceOptions.forEach((roleOptions: any) => {
        roleOptions.forEach((option: IInstanceOption) => {
          if (option.selected) {
            acc.push({
              roleId: option.roleId,
              tenantId: option.value,
            });
          }
        });
      });
      return acc;
    }, []);
  }
);

export const getAssignRolesStepRows = createSelector(
  [getState],
  ({ assignRolesTable }) => assignRolesTable
);

export const getAssignRolesWithInstancesStepRows = createSelector(
  [getState],
  ({ assignRolesWithInstancesTable }) => assignRolesWithInstancesTable
);

export const getIsFinishAndInviteEnabled = createSelector(
  [getRolesOptions],
  (rolesOptions) =>
    Object.values(rolesOptions).some((availableRoles: any) =>
      availableRoles.some(({ selected }: IOption) => selected)
    )
);

export const getIsGroupDrawerVisible = createSelector(
  [getState],
  ({ isGroupDrawerVisible }) => isGroupDrawerVisible
);

export const getEditUser = createSelector(
  [getState],
  ({ editUser }) => editUser
);

export const getAssignedRoles = createSelector(
  [getEditUser],
  ({ roles }) => roles
);

export const getUnassignedRolesOptions = createSelector(
  [getState, getAssignedRoles],
  ({ rolesOptions }, assignedRoles) =>
    removeUserRolesFromRolesOptions(rolesOptions, assignedRoles)
);

export const getProductsOptionsWithUnassignedRoles = createSelector(
  [getState, getUnassignedRolesOptions],
  ({ productsOptions }, unassignedRolesOptions) =>
    removeProductsOptionsWithoutUnassignedRoles(
      productsOptions,
      unassignedRolesOptions
    )
);

export const getAllRoles = createSelector(
  [getState],
  ({ allRoles }) => allRoles
);

// Filters ONLY STATIC roles by Tenants. Roles with other types returns as is.
// Filter based on provisioned Product instances.
// Check "filterRolesByExistingProducts()".
export const getAllRolesByExistingProducts = createSelector(
  [getAllRoles, getAllProvisionedProductsInstances],
  (allRoles, allProvisionedProductsInstances) =>
    filterRolesByExistingProducts(allRoles, allProvisionedProductsInstances)
);

export const getAllGroups = createSelector(
  [getState],
  ({ allGroups }) => allGroups
);

export const getRolesWithProvisionedInstances = createSelector(
  [getAllRolesByExistingProducts, getAllProductsInstances],
  (allRoles, { items: allProductsInstances }) => {
    const provisionedInstancesByProductKey = groupBy(
      getProvisionedProductInstances(allProductsInstances),
      'productType'
    );

    const rolesWithInstances = allRoles.reduce<IRolesWithInstances[]>(
      (acc, role) => {
        const instances = role.productKey
          ? provisionedInstancesByProductKey[role.productKey]
          : [];

        if (!instances?.length) return acc;

        acc.push({
          ...role,
          productAndRole: `${role?.product} - ${role?.roleDisplayName}`,
          instances,
        });

        return acc;
      },
      []
    );

    return rolesWithInstances;
  }
);

export const getProductsAndRolesOptions = createSelector(
  [getRolesWithProvisionedInstances],
  (rolesWithInstances) =>
    rolesWithInstances.map((roleWithInstances) => {
      return {
        label: roleWithInstances.productAndRole,
        value: roleWithInstances.id,
        selected: false,
      };
    })
);

export const getSelectedProductsAndRoles = createSelector(
  [getAssignRolesWithInstancesStepRows],
  (assignRolesWithInstancesStepRows) =>
    assignRolesWithInstancesStepRows.reduce<string[]>((acc, row) => {
      if (row.selectedRole) {
        acc.push(row.selectedRole);
      }
      return acc;
    }, [])
);

export const getInstancesOptions = createSelector(
  [
    getRolesWithProvisionedInstances,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE]),
  ],
  (rolesWithInstances, useRoleDescriptionForProductInstanceDisplay) => {
    return rolesWithInstances.reduce<IDependentOption>((acc, role) => {
      acc[role.productAndRole] = role.instances.map((instance) => {
        return {
          value: instance.id,
          roleId: role.id,
          label: getProductInstanceDisplayName(
            instance,
            useRoleDescriptionForProductInstanceDisplay
          ),
          selected: false,
        };
      });
      return acc;
    }, {});
  }
);

export const getAssignRolesDrawer = createSelector(
  [getEditUser],
  ({ assignRolesDrawer }) => assignRolesDrawer
);
export const getAssignRolesDrawerTable = createSelector(
  [getAssignRolesDrawer],
  ({ assignRolesTable }) => assignRolesTable
);

export const getAssignRolesWithInstancesTable = createSelector(
  [getAssignRolesDrawer],
  ({ assignRolesWithInstancesTable }) => assignRolesWithInstancesTable
);

export const getSelectedProductsAndRolesInDrawer = createSelector(
  [getAssignRolesWithInstancesTable],
  (assignRolesWithInstancesTable) =>
    assignRolesWithInstancesTable.map((row) => row.selectedRole).filter(Boolean)
);

export const getUnassignedInstancesOptions = createSelector(
  [
    getRolesWithProvisionedInstances,
    getAssignedRoles,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE]),
  ],
  (
    rolesWithInstances,
    assignedRoles,
    useRoleDescriptionForProductInstanceDisplay
  ) => {
    return rolesWithInstances.reduce<Record<string, IProductInstanceOption[]>>(
      (acc, role) => {
        const unassignedInstances = role.instances.filter((instance) => {
          return !assignedRoles.find((assignedRoleAndInstanceIds) => {
            return (
              assignedRoleAndInstanceIds.roleId === role.id &&
              (assignedRoleAndInstanceIds.tenantId === instance.id ||
                instance.id === ENTERPRISE_PRODUCT_TYPE)
            );
          });
        });

        if (!unassignedInstances.length) return acc;

        acc[role.productAndRole] = unassignedInstances.map((instance) => {
          return {
            value: instance.id,
            label: getProductInstanceDisplayName(
              instance,
              useRoleDescriptionForProductInstanceDisplay
            ),
            roleId: role.id,
            selected: false,
          };
        });
        return acc;
      },
      {}
    );
  }
);

export const getUnassignedProductsAndRolesOptions = createSelector(
  [getUnassignedInstancesOptions],
  (unassignedInstancesOptions) =>
    Object.keys(unassignedInstancesOptions).map((productAndRoleName) => {
      return {
        label: productAndRoleName,
        value: productAndRoleName,
        selected: false,
      };
    })
);

export const getSccRoleOptionsWithInstances = createSelector(
  [getAssignRolesDrawer],
  ({ sccRole }) => {
    return [
      {
        value: RolesPermission.MEMBER,
        label: `${ENTERPRISE_PRODUCT_NAME} - ${RolesPermission.MEMBER}`,
        selected: sccRole === RolesPermission.MEMBER,
      },
      {
        value: RolesPermission.ADMINISTRATOR,
        label: `${ENTERPRISE_PRODUCT_NAME} - ${RolesPermission.ADMINISTRATOR}`,
        selected: sccRole === RolesPermission.ADMINISTRATOR,
      },
    ];
  }
);

export const getSelectedInstancesIDsInDrawer = createSelector(
  [getAssignRolesWithInstancesTable],
  (assignRolesWithInstancesTable) => {
    const selectedInstancesIDsInDrawer: Array<{
      roleId: string;
      instanceId: string;
    }> = assignRolesWithInstancesTable.reduce((acc: any, row) => {
      if (!row.selectedRole) return acc;
      const instanceOptions = Object.values(row.instancesOptions);
      instanceOptions.forEach((roleOptions: any) => {
        roleOptions.forEach((option: IInstanceOption) => {
          if (option.selected) {
            acc.push({
              roleId: option.roleId,
              instanceId: option.value,
            });
          }
        });
      });
      return acc;
    }, []);
    return Array.from(
      new Set(
        selectedInstancesIDsInDrawer.map((item: Object) => JSON.stringify(item))
      )
    ).map((item) => JSON.parse(item));
  }
);

export const getSccRoleOptions = createSelector(
  [getAssignRolesDrawer],
  ({ sccRole }) => {
    return [
      {
        value: RolesPermission.MEMBER,
        label: RolesPermission.MEMBER,
        selected: sccRole === RolesPermission.MEMBER,
      },
      {
        value: RolesPermission.ADMINISTRATOR,
        label: RolesPermission.ADMINISTRATOR,
        selected: sccRole === RolesPermission.ADMINISTRATOR,
      },
    ];
  }
);

export const getSelectedProductsInAssignRolesDrawer = createSelector(
  [getAssignRolesDrawer],
  ({ assignRolesTable }) =>
    assignRolesTable.map((row) => row.selectedProduct).filter(Boolean)
);

export const getSelectedRolesIDsInAssignRolesDrawer = createSelector(
  [getAssignRolesDrawer],
  ({ assignRolesTable }) => {
    const selectedRolesValues: string[] = [];

    for (const row of assignRolesTable) {
      if (row.selectedProduct) {
        const selectedProductRoles = row.rolesOptions[row.selectedProduct];
        const rolesIDs = selectedProductRoles
          .filter((role: IOption) => role.selected)
          .map((role: IOption) => role.value);
        selectedRolesValues.push(...rolesIDs);
      }
    }

    return selectedRolesValues;
  }
);

export const getSccRoleFromDrawer = createSelector(
  [getAssignRolesDrawer],
  ({ sccRole }) => sccRole
);

export const getDefaultSccRoleFromDrawer = createSelector(
  [getUserDetails],
  (userDetails) => {
    return userDetails?.isAdmin
      ? RolesPermission.ADMINISTRATOR
      : RolesPermission.MEMBER;
  }
);

export const getIsSCCRoleChanged = createSelector(
  [getUserDetails, getSccRoleFromDrawer],
  (userDetails, sccRole) => {
    const defaultSccRoleFromDrawer = userDetails?.isAdmin
      ? RolesPermission.ADMINISTRATOR
      : RolesPermission.MEMBER;
    return defaultSccRoleFromDrawer !== sccRole;
  }
);

export const getAssignedRolesTableData = createSelector(
  [
    getAllRolesByExistingProducts,
    getAssignedRoles,
    getDefaultSccRoleFromDrawer,
  ],
  (allRoles, assignedRoles, sccRole) => {
    const matchedRoles = allRoles
      .map((role: IRbacUserRole) => {
        const matchedRole = assignedRoles.find(
          (assignedRole: IRbacAssignedRole) => role.id === assignedRole.roleId
        );

        const output = matchedRole
          ? {
              id: matchedRole.id,
              name: role.roleDisplayName,
              product: role.product,
            }
          : false;

        return output;
      })
      .filter(Boolean) as IRbacUserRole[];

    const tableData = [
      {
        id: '',
        role: sccRole,
        product: ENTERPRISE,
      },
      ...matchedRoles.map((role: IRbacUserRole) => ({
        id: role.id,
        role: role.name,
        product: role.product,
      })),
    ];

    return tableData;
  }
);

export const getAssignedRolesWithInstancesTableData = createSelector(
  [
    getAllRolesByExistingProducts,
    getAssignedRoles,
    getDefaultSccRoleFromDrawer,
    getAllProductsInstances,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE]),
  ],
  (
    allRoles,
    assignedRoles,
    sccRole,
    allProductsInstancesState,
    useRoleDescriptionForProductInstanceDisplay
  ) => {
    const assignedRolesTableData = assignedRoles
      .map((assignedRole) => {
        const matchedRole = allRoles.find(
          (role) => role.id === assignedRole.roleId
        );
        const matchedInstance = allProductsInstancesState.items.find(
          (instance) => instance.id === assignedRole.tenantId
        );

        if (!matchedRole) {
          return null;
        }

        const baseData = {
          id: assignedRole.id,
          roleType: capitalize(matchedRole.type),
          product: matchedRole.product,
          productType: matchedRole.productKey,
          delegatable: matchedRole.delegatable,
        };

        // static & custom roles
        if (matchedInstance) {
          const tenantName = getProductInstanceDisplayName(
            matchedInstance,
            useRoleDescriptionForProductInstanceDisplay
          );
          return {
            ...baseData,
            role: matchedRole.roleDisplayName,
            productAndRole: `${matchedRole.productKey === ENTERPRISE_PRODUCT_TYPE ? ENTERPRISE_PRODUCT_NAME : matchedRole.product} - ${matchedRole.roleDisplayName}`,
            instanceName: tenantName,
            region: matchedInstance.region.name,
            tenantId: matchedInstance.id,
          };
        }

        // bundled roles
        if (matchedRole.productRoles) {
          return {
            ...baseData,
            role: matchedRole.roleDisplayName,
            productAndRole: `${matchedRole.roleDisplayName}`,
            instanceName: ENTERPRISE,
            region: EnterpriseRegionType.GLOBAL,
          };
        }

        return null;
      })
      .filter(Boolean) as IAssignedRoleWIthInstanceTableRow[];

    const tableData: IAssignedRoleWIthInstanceTableRow[] = [
      {
        id: '',
        role: sccRole,
        product: ENTERPRISE,
        productType: ENTERPRISE_PRODUCT_TYPE,
        productAndRole: `${ENTERPRISE_PRODUCT_NAME} - ${sccRole}`,
        instanceName: ENTERPRISE,
        region: EnterpriseRegionType.GLOBAL,
        roleType: capitalize(UserRoleType.STATIC),
        delegatable: false,
      },
      ...assignedRolesTableData,
    ];

    return tableData;
  }
);

export const getAssignedRolesTablePagination = createSelector(
  [getEditUser],
  ({ assignedRolesTablePagination }) => assignedRolesTablePagination
);

export const getFilteredAssignedRolesTableData = createSelector(
  [getAssignedRolesWithInstancesTableData, getEditUser],
  (
    tableData,
    {
      assignRolesTable: {
        search,
        productFilterSelectedOption,
        regionFilterSelectedOption,
        typesFilterSelectedOption,
        sortingOptions,
      },
    }
  ) => {
    const filterBySearch = (): IAssigneRoleRow[] => {
      const result = search
        ? tableData.filter(({ productAndRole, region }) => {
            const searchValue = search.toLowerCase().trim();
            const hasMatch =
              productAndRole?.toLowerCase().includes(searchValue) ||
              region?.toLowerCase().includes(searchValue);
            return hasMatch;
          })
        : tableData;

      return result as any;
    };

    const filterByProduct = (data: IAssigneRoleRow[]): IAssigneRoleRow[] => {
      const result = productFilterSelectedOption
        ? data.filter(({ productType }) => {
            return productType === productFilterSelectedOption.value;
          })
        : data;

      return result;
    };

    const filterByRegion = (data: IAssigneRoleRow[]): IAssigneRoleRow[] => {
      const result = regionFilterSelectedOption
        ? data.filter((role) => {
            return (
              role.region === regionFilterSelectedOption.value ||
              role.product === regionFilterSelectedOption.label
            );
          })
        : data;

      return result;
    };

    const filterByType = (data: IAssigneRoleRow[]): IAssigneRoleRow[] => {
      const result = typesFilterSelectedOption
        ? data.filter(
            (role) => role.roleType === typesFilterSelectedOption.value
          )
        : data;
      return result;
    };

    const composedFunction = composeFunctions(
      filterBySearch,
      filterByProduct,
      filterByRegion,
      filterByType
    ) as Function;

    const output = composedFunction();

    const sortedOutput = sortTableData<IAssigneRoleRow>(output, sortingOptions);
    return sortedOutput;
  }
);

export const getSlicedAssignedRolesTableData = createSelector(
  [getFilteredAssignedRolesTableData, getAssignedRolesTablePagination],
  (filteredAssignedRoles, { pageSize, pageIndex }) => {
    const slicedTableData = sliceTableData<IRbacTableDataRole>(
      pageIndex,
      pageSize,
      filteredAssignedRoles
    );

    return slicedTableData;
  }
);

export const getSlicedAssignedRoleswithInstancesTableData = createSelector(
  [getFilteredAssignedRolesTableData, getAssignedRolesTablePagination],
  (filteredAssignedRoles, { pageSize, pageIndex }) => {
    const slicedTableData = sliceTableData<IRbacTableDataRole>(
      pageIndex,
      pageSize,
      filteredAssignedRoles
    );

    return slicedTableData;
  }
);

export const getAssignedRolesTablePageCount = createSelector(
  [getFilteredAssignedRolesTableData, getAssignedRolesTablePagination],
  (assignedRoles, { pageSize }) => {
    return getTablePaginationPagesCount(assignedRoles.length, pageSize);
  }
);

export const getProductFilterSelectedOption = createSelector(
  [getEditUser],
  ({ assignRolesTable }) => assignRolesTable.productFilterSelectedOption
);

export const getProducts = createSelector(
  [getAllProductsInstances],
  ({ items }) => {
    const products = items.map(
      ({ productName, productType }: IProductInstance) => ({
        label: productName,
        value: productType,
      })
    );
    const result = [
      {
        label: ENTERPRISE_PRODUCT_NAME,
        value: ENTERPRISE_PRODUCT_TYPE,
      },
      ...products,
    ];

    return uniqBy(result, 'value');
  }
);

export const getRegionFilterSelectedOption = createSelector(
  [getEditUser],
  ({ assignRolesTable }) => assignRolesTable.regionFilterSelectedOption
);

export const getRegionFilterOptions = createSelector(
  [getEditUser, getRegionFilterSelectedOption],
  (
    { assignRolesTable: { regionFilterOptions } },
    regionFilterSelectedOption
  ) => {
    return regionFilterSelectedOption
      ? regionFilterOptions.map((option) => {
          if (regionFilterSelectedOption.value === option.value) {
            return regionFilterSelectedOption;
          }
          return option;
        })
      : regionFilterOptions;
  }
);

export const getTypesFilterOptions = createSelector(
  [getEditUser, getAssignedRolesWithInstancesTableData],
  ({ assignRolesTable: { typesFilterOptions } }) => typesFilterOptions
);

export const getAssignedGroups = createSelector(
  [getEditUser],
  ({ groups }) => groups
);

export const getGroupsToRolesRelations = createSelector(
  [getState],
  ({ groupsToRolesRelations }) => groupsToRolesRelations
);

export const getAssignedGroupsTableData = createSelector(
  [
    getAllGroups,
    getAssignedGroups,
    getAllRolesByExistingProducts,
    getGroupsToRolesRelations,
    getAllProductsInstances,
  ],
  (
    allGroups,
    assignedGroups,
    allRoles,
    groupsToRolesRelations,
    { items: allProductsInstances }
  ) => {
    if (isEmpty(assignedGroups) || groupsToRolesRelations?.loading) {
      return [];
    }

    const provisionedProductInstances =
      getProvisionedProductInstances(allProductsInstances);

    const tableData = assignedGroups.map((assignedGroup: IRbacGroup) => {
      const { id, groupId } = assignedGroup;
      const groupData: IRbacGroup | undefined = find(allGroups, {
        id: groupId,
      });

      const assignedRoles = reduce(
        groupsToRolesRelations?.items,
        (acc: IAssignedRole[], relation: IGroupToRoleRelation) => {
          if (relation.groupId !== groupId) {
            return acc;
          }

          const role: IRbacUserRole | undefined = find(allRoles, {
            id: relation.roleId,
          });

          if (!role) return acc;

          const { product, roleDisplayName, id, delegatable } = role;

          const duplicateRole = acc.find(
            (r) => r.roleDisplayName === roleDisplayName
          );

          const extraInstanceData = provisionedProductInstances.find(
            (i) => i.id === relation.tenantId
          );

          if (!extraInstanceData || duplicateRole) return acc;

          acc.push({
            roleDisplayName,
            roleId: id,
            tenantId: relation.tenantId,
            productName: product,
            delegatable,
            regionName: extraInstanceData.region.name,
          });

          return acc;
        },
        []
      );

      return {
        id,
        groupId,
        type: groupData?.type ?? 'Local',
        name: groupData?.name ?? '',
        description: groupData?.description ?? '',
        assignedRoles,
      };
    });

    return tableData;
  }
);

export const getGroupsTablePagination = createSelector(
  [getEditUser],
  ({ groupsTablePagination }) => groupsTablePagination
);

export const getFilteredGroupsTableData = createSelector(
  [getAssignedGroupsTableData, getEditUser],
  (tableData, { groupsTable: { search, sortingOptions } }) => {
    const filterDataBySearch = search
      ? tableData.filter(
          (group) =>
            group.name.toLowerCase().includes(search.toLowerCase()) ||
            Object.values(group.assignedRoles)
              .flat()
              .join(', ')
              .toLowerCase()
              .includes(search.toLowerCase())
        )
      : tableData;

    const sortedData = sortTableData<IRbacTableDataGroup>(
      filterDataBySearch,
      sortingOptions
    );

    return sortedData;
  }
);

export const getSlicedGroupsTableData = createSelector(
  [getFilteredGroupsTableData, getGroupsTablePagination],
  (filteredGroups, { pageSize, pageIndex }) => {
    const slicedTableData = sliceTableData<IRbacTableDataGroup>(
      pageIndex,
      pageSize,
      filteredGroups
    );

    return slicedTableData;
  }
);

export const getGroupsTablePageCount = createSelector(
  [getFilteredGroupsTableData, getGroupsTablePagination],
  (assignedGroupsTableData, { pageSize }) => {
    return getTablePaginationPagesCount(
      assignedGroupsTableData.length,
      pageSize
    );
  }
);

export const getActiveRemoveRoleId = createSelector(
  [getEditUser],
  ({ activeRemoveRoleId }) => activeRemoveRoleId
);

export const getActiveRemoveRoleWithInstanceRowData = createSelector(
  [getEditUser],
  ({ activeRemoveRoleWithInstanceRowData }) =>
    activeRemoveRoleWithInstanceRowData
);

export const getActiveRemoveGroupId = createSelector(
  [getEditUser],
  ({ activeRemoveGroupId }) => activeRemoveGroupId
);

export const getActiveRemoveRoleDetails = createSelector(
  [getActiveRemoveRoleId, getAssignedRolesTableData],
  (activeRemoveRoleId, assignedRoles) =>
    assignedRoles.find((role) => role.id === activeRemoveRoleId)
);

export const getActiveRemoveRoleDetailsWithInstance = createSelector(
  [getActiveRemoveRoleId, getAssignedRolesWithInstancesTableData],
  (activeRemoveRoleId, assignedRoles) => {
    return assignedRoles.find((role) => role.id === activeRemoveRoleId);
  }
);

export const getActiveRemoveGroupDetails = createSelector(
  [getActiveRemoveGroupId, getAssignedGroupsTableData],
  (activeRemoveGroupId, assignedGroups) =>
    assignedGroups.find((group) => group.id === activeRemoveGroupId)
);

export const getIsAssignRolesDrawerVisible = createSelector(
  [getEditUser],
  ({ isAssignRolesDrawerVisible }) => isAssignRolesDrawerVisible
);

export const getIsAssignGroupsDrawerVisible = createSelector(
  [getEditUser],
  ({ isAssignGroupsDrawerVisible }) => isAssignGroupsDrawerVisible
);

export const getProductOptionsFromDrawer = createSelector(
  [getEditUser],
  ({ productOptionsFromDrawer }) => productOptionsFromDrawer
);

export const getPristineRolesOptionsFromDrawer = createSelector(
  [getEditUser],
  ({ pristineRolesOptionsFromDrawer }) => pristineRolesOptionsFromDrawer
);

export const getSelectedProductOptionFromDrawer = createSelector(
  [getProductOptionsFromDrawer],
  (productOptionsFromDrawer) =>
    productOptionsFromDrawer.find((option: CDSOption) => option.selected)
);

export const getRolesOptionsFromDrawer = createSelector(
  [getEditUser],
  ({ rolesOptionsFromDrawer }) => rolesOptionsFromDrawer
);

export const getSelectedRolesOptionsFromDrawer = createSelector(
  [getRolesOptionsFromDrawer],
  (rolesOptionsFromDrawer) =>
    rolesOptionsFromDrawer.filter((option: CDSOption) => option.selected)
);

export const getPristineProductOptionsFromDrawer = createSelector(
  [getProductOptionsFromDrawer],
  (productOptionsFromDrawer) =>
    productOptionsFromDrawer.map((option: CDSOption) => ({
      ...option,
      selected: false,
    }))
);

export const getGroupsOptionsFromDrawer = createSelector(
  [getEditUser],
  ({ groupsOptionsFromDrawer }) => groupsOptionsFromDrawer
);

export const getSelectedGroupsOptionsFromDrawer = createSelector(
  [getGroupsOptionsFromDrawer],
  (groupsOptionsFromDrawer) =>
    groupsOptionsFromDrawer.filter((option: CDSOption) => option.selected)
);

export const getPristineGroupsOptionsFromDrawer = createSelector(
  [getGroupsOptionsFromDrawer],
  (groupsOptionsFromDrawer) =>
    groupsOptionsFromDrawer.map((option: CDSOption) => ({
      ...option,
      selected: false,
    }))
);

export const getOpenedUser = createSelector(
  [getActiveEditUserId, getAllUsers],
  (activeEditUserId, allUsers) => {
    return allUsers.find((user: IUser) => user.id === activeEditUserId);
  }
);

export const getIsDiscardChangesModalVisible = createSelector(
  [getState],
  ({ isDiscardChangesModalVisible }) => isDiscardChangesModalVisible
);

export const getIsIncompleteUserDataModalVisible = createSelector(
  [getState],
  ({ isIncompleteUserDataModalVisible }) => isIncompleteUserDataModalVisible
);

export const getIsSuccessCreatedGroupNotificationVisible = createSelector(
  [getState],
  ({ isSuccessCreatedGroupNotificationVisible }) =>
    isSuccessCreatedGroupNotificationVisible
);

export const getIsErrorInviteUsersNotificationVisible = createSelector(
  [getState],
  ({ isErrorInviteUsersNotificationVisible }) =>
    isErrorInviteUsersNotificationVisible
);

export const getUsersIsLoading = createSelector(
  [getState],
  ({ isLoading }) => isLoading
);

export const getUsersError = createSelector([getState], ({ error }) => error);

export const getNotificationType = createSelector(
  [getState],
  ({ notificationType }) => notificationType
);

export const getFileName = createSelector(
  [getState],
  ({ uploadFile }) => uploadFile.fileName
);

export const getProgressBar = createSelector(
  [getState],
  ({ uploadFile }) => uploadFile.progressBar
);

export const getAssignedRolesTableSearch = createSelector(
  [getEditUser],
  ({ assignRolesTable: { search } }) => search
);

export const getGroupsTableSearch = createSelector(
  [getEditUser],
  ({ groupsTable: { search } }) => search
);

export const getAssignedRolesByUserId = createSelector(
  [getState],
  ({
    assignedRolesByUserId,
    assignedGroupRolesByUserId,
    fetchingUserDataIds,
  }) => {
    return {
      assignedRolesByUserId,
      assignedGroupRolesByUserId,
      fetchingUserDataIds,
    };
  }
);

export const getKPARoleAssignments = createSelector(
  [getState, getAllRolesByExistingProducts, getCurrentUserId],
  ({ assignedRolesByUserId, assignedGroupRolesByUserId }, allRoles, userId) => {
    if (!userId) {
      return {
        kpaDirectlyAssignedRoles: [],
        kpaGroupRoles: [],
      };
    }

    const kpaDirectlyAssignedRoles = (
      assignedRolesByUserId[userId] ?? []
    ).filter((r) => {
      const rbacRole = allRoles.find((role) => role.id === r.roleId);

      return rbacRole?.delegatable;
    });

    const groupRoles = (assignedGroupRolesByUserId[userId] ?? [])
      .map((g) => g.mappedRoles)
      .flat();

    const kpaGroupRoles = groupRoles.filter((r) => {
      const rbacRole = allRoles.find((role) => role.id === r.roleId);

      return rbacRole?.delegatable;
    });

    return {
      kpaDirectlyAssignedRoles,
      kpaGroupRoles,
    };
  }
);

export const getKPAManagedTenantIds = createSelector(
  [getKPARoleAssignments],
  ({ kpaDirectlyAssignedRoles, kpaGroupRoles }) => {
    return uniq([
      ...kpaDirectlyAssignedRoles.map((r) => r.tenantId),
      ...kpaGroupRoles.map((r) => r.tenantId),
    ]);
  }
);

export const getKPAManagedTenants = createSelector(
  [getKPAManagedTenantIds, getAllProductsInstances],
  (tenantIds, productInstances) => {
    return productInstances.items.filter(
      (i) =>
        tenantIds.includes(i.id) &&
        i.provisioningStatus === ProvisioningStatus.FINISHED
    );
  }
);

export const getIsKPA = createSelector([getKPAManagedTenantIds], (tenantIds) =>
  Boolean(tenantIds.length)
);

export const getKPAManagedUsers = createSelector(
  [getState, getAllUsers, getKPAManagedTenantIds],
  (
    { assignedGroupRolesByUserId, assignedRolesByUserId },
    allUsers,
    kpaManagedTenantIds
  ) => {
    const partiallyManagedUserIds: string[] = [];
    const fullyManagedUserIds: string[] = [];

    const users = !kpaManagedTenantIds.length
      ? allUsers
      : allUsers.filter((user) => {
          const userAssignedRoles = assignedRolesByUserId[user.id] ?? [];
          const userAssignedGroupRoles =
            assignedGroupRolesByUserId[user.id] ?? [];

          if (!userAssignedRoles.length && !userAssignedGroupRoles.length)
            return false;

          const userTenantIds = uniq([
            ...userAssignedRoles.map((r) => r.tenantId),
            ...userAssignedGroupRoles
              .map((g) => g.mappedRoles.map((r) => r.tenantId))
              .flat(),
          ]);

          const tenantsIntersection = intersection(
            kpaManagedTenantIds,
            userTenantIds
          );

          if (!tenantsIntersection.length) return false;

          // User can have roles in other tenants we are not KPA for
          if (
            tenantsIntersection.length === kpaManagedTenantIds.length &&
            tenantsIntersection.length === userTenantIds.length
          ) {
            fullyManagedUserIds.push(user.id);
          } else {
            partiallyManagedUserIds.push(user.id);
          }

          return true;
        });

    return {
      users,
      partiallyManagedUserIds,
      fullyManagedUserIds,
    };
  }
);

export const getKPAManagedUsersOptions = createSelector(
  [getKPAManagedUsers],
  ({ users }) => {
    return users.map((user) => ({
      value: user.id,
      label: `${user.profile.firstName} ${user.profile.lastName} (${user.profile.email})`,
    }));
  }
);

export const getIsUserRolesAssignmentsLoading = createSelector(
  [getState, getCurrentUserId],
  ({ fetchingUserDataIds }, kpaAdminId) => {
    // Ignore currently logged in KPA cause of constant KPA data refetch
    if (
      fetchingUserDataIds.length === 1 &&
      kpaAdminId &&
      fetchingUserDataIds.includes(kpaAdminId)
    ) {
      return false;
    }

    return Boolean(fetchingUserDataIds.length);
  }
);

export const getKPAManagedGroups = createSelector(
  [getKPAManagedTenantIds, getAllGroups, getGroupsToRolesRelations],
  (tenantIds, allGroups, groupsToRolesRelations) => {
    return classifyGroupsForKpa(tenantIds, allGroups, groupsToRolesRelations);
  }
);

export const getKPAManagedGroupsOptions = createSelector(
  [getKPAManagedGroups, getGroupsOptions],
  (managedGroups, groupsOptions) => {
    const { fullyManagedGroups, noRolesGroups } = managedGroups;

    const groups = [...fullyManagedGroups, ...noRolesGroups];

    return groupsOptions.filter((o) => groups.find((g) => g.id === o.value));
  }
);

export const getKPAManagedProductsAndRoles = createSelector(
  [getAllRolesByExistingProducts, getKPAManagedTenants],
  (allRoles, tenants) => {
    const tenantsByProductKey = groupBy(tenants, 'productType');

    return allRoles.reduce<IRolesWithInstances[]>((acc, r) => {
      const productTenants = r.productKey
        ? tenantsByProductKey[r.productKey]
        : [];

      if (!productTenants?.length) return acc;

      const roleWithInstance: IRolesWithInstances = {
        ...r,
        productAndRole: `${r?.product} - ${r?.roleDisplayName}`,
        instances: productTenants,
      };

      acc.push(roleWithInstance);

      return acc;
    }, []);
  }
);

export const getRolesByPermissions = createSelector(
  [getAllRolesByExistingProducts, getKPAManagedProductsAndRoles, getIsKPA],
  (allRoles, productRolesManagedByKPA, isKPA) => {
    return isKPA ? productRolesManagedByKPA : allRoles;
  }
);

export const getKPAManagedProductsAndRolesOptions = createSelector(
  [getKPAManagedProductsAndRoles],
  (roles) => {
    return roles.map((r) => ({
      label: r.productAndRole,
      value: r.id,
      selected: false,
    }));
  }
);

export const getKPAManagedInstancesOptions = createSelector(
  [getKPAManagedProductsAndRoles],
  (rolesWithInstances) => {
    return convertRolesToInstanceDictionary(rolesWithInstances);
  }
);

export const getEditedUserManagedTenantsIds = createSelector(
  [getUserDetails, getAssignedRolesByUserId],
  (userDetails, { assignedRolesByUserId, assignedGroupRolesByUserId }) => {
    if (!userDetails?.id) return [];

    const userId = userDetails.id;

    const userRoles = assignedRolesByUserId[userId] ?? [];
    const userGroupRoles = assignedGroupRolesByUserId[userId] ?? [];

    if (!userRoles.length && !userGroupRoles?.length) return [];

    const userRolesManagedTenants = userRoles.map((role) => role.tenantId);
    const userGroupRolesManagedTenants = userGroupRoles
      .map((group) => {
        return group.mappedRoles.map((r) => r.tenantId);
      })
      .flat();

    const userTenantsIds = uniq([
      ...userRolesManagedTenants,
      ...userGroupRolesManagedTenants,
    ]);

    return userTenantsIds;
  }
);

export const getIsUserHasForeignTenants = createSelector(
  [getEditedUserManagedTenantsIds, getKPAManagedTenantIds],
  (userTenantsIds, kpaTenantsIds) => {
    if (!userTenantsIds.length) return false;

    const tenantsIdsIntersection = intersection(userTenantsIds, kpaTenantsIds);

    return tenantsIdsIntersection.length !== userTenantsIds.length;
  }
);

export const getKpaManagedEditedUserRoles = createSelector(
  [getKPAManagedTenantIds, getFilteredAssignedRolesTableData],
  (tenantIds, assignedRoles) => {
    return assignedRoles.filter((r) => {
      return 'tenantId' in r && tenantIds.includes(r.tenantId as string);
    });
  }
);

export const getSlicedKpaManagedEditedUserRoles = createSelector(
  [getKpaManagedEditedUserRoles, getAssignedRolesTablePagination],
  (filteredAssignedRoles, { pageSize, pageIndex }) => {
    return sliceTableData<IRbacTableDataRole>(
      pageIndex,
      pageSize,
      filteredAssignedRoles
    );
  }
);

export const getEditedUserRoles = createSelector(
  [
    getFilteredAssignedRolesTableData,
    getAssignedRolesWithInstancesTableData,
    getKpaManagedEditedUserRoles,

    (state) =>
      hasFeatureFlags(state, [
        FEATURE_FLAG_CONSTANTS.ROLES_MANAGEMENT_DELEGATION,
      ]),
    getIsKPA,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.USER_MANAGEMENT]),
  ],
  (
    assignedRoles,
    assignedRolesWithInstances,
    kpaManagedUserAssignedRoles,
    hasRoleDelegationFF,
    isActiveKPA,
    hasTenantFeatureFlag
  ) => {
    if (isActiveKPA && hasRoleDelegationFF) {
      return kpaManagedUserAssignedRoles;
    }

    if (hasTenantFeatureFlag) {
      return assignedRolesWithInstances;
    }

    return assignedRoles;
  }
);

export const getProductFilterOptions = createSelector(
  [getEditUser, getProductFilterSelectedOption, getEditedUserRoles],
  (
    { assignRolesTable: { productFilterOptions } },
    productFilterSelectedOption,
    assignedUserRoles
  ) => {
    return (
      productFilterSelectedOption
        ? productFilterOptions.map((option) => {
            if (productFilterSelectedOption.value === option.value) {
              return productFilterSelectedOption;
            }
            return option;
          })
        : productFilterOptions
    ).filter((product) =>
      assignedUserRoles.some(
        (role) =>
          role.productType.toLowerCase() === product.value?.toLowerCase()
      )
    );
  }
);

export const getKpaManagedUserRolesTablePageCount = createSelector(
  [getKpaManagedEditedUserRoles, getAssignedRolesTablePagination],
  (assignedRoles, { pageSize }) => {
    return getTablePaginationPagesCount(assignedRoles.length, pageSize);
  }
);

export const getKpaManagedEditedUserGroups = createSelector(
  [getKPAManagedTenantIds, getFilteredGroupsTableData],
  (tenantIds, filteredGroups) => {
    const kpaManagedUserGroups = filteredGroups.filter((g) =>
      g.assignedRoles.every((r) => tenantIds.includes(r.tenantId))
    );

    return kpaManagedUserGroups;
  }
);

export const getKpaManagedUserGroupsTablePageCount = createSelector(
  [getKpaManagedEditedUserGroups, getGroupsTablePagination],
  (groups, { pageSize }) => {
    return getTablePaginationPagesCount(groups.length, pageSize);
  }
);

export const getSlicedKPAManagedUserGroupsTableData = createSelector(
  [getKpaManagedEditedUserGroups, getGroupsTablePagination],
  (kpaManagedUserGroups, { pageSize, pageIndex }) => {
    return sliceTableData<IRbacTableDataGroup>(
      pageIndex,
      pageSize,
      kpaManagedUserGroups
    );
  }
);

export const getKPAManagedGroupsOptionsFromDrawer = createSelector(
  [getKPAManagedGroups, getGroupsOptionsFromDrawer],
  (managedGroups, groupsOptionsFromDrawer) => {
    const { fullyManagedGroups, noRolesGroups } = managedGroups;

    const groups = [...fullyManagedGroups, ...noRolesGroups];

    return groupsOptionsFromDrawer.filter((o) =>
      groups.find((g) => g.id === o.value)
    );
  }
);

export const getKpaManagedInstancesOptionsForRolesDrawer = createSelector(
  [getUnassignedInstancesOptions, getKPAManagedTenantIds],
  (instances, tenantIds) => {
    return Object.entries(instances).reduce<
      Record<string, IProductInstanceOption[]>
    >((prev, curr) => {
      const [productName, productInstances] = curr;

      const kpaManagedProductInstances = productInstances.filter((i) =>
        tenantIds.includes(i.value)
      );

      if (!kpaManagedProductInstances.length) return prev;

      prev[productName] = kpaManagedProductInstances;

      return prev;
    }, {});
  }
);

export const getUserFromEnterprise = createSelector(
  [getAllUsers, getInviteUsers],
  (users, inviteUsers) => {
    const usersAlreadyExistInEnterprise: any[] = [];
    const usersNotExistInEnterprise: any[] = [];

    const getUsersEmails = inviteUsers.map((item) => item.email.value);

    users.forEach((user) => {
      if (getUsersEmails.includes(user.profile.email)) {
        usersAlreadyExistInEnterprise.push(user);
      }
    });

    inviteUsers.forEach((inviteUser) => {
      if (
        !users.some((user) => user.profile.email === inviteUser.email.value)
      ) {
        usersNotExistInEnterprise.push(inviteUser);
      }
    });

    return {
      usersAlreadyExistInEnterprise,
      usersNotExistInEnterprise,
    };
  }
);

export const getRoleToShowNotificationBannerForKPA = createSelector(
  [
    getKpaManagedEditedUserGroups,
    getActiveRemoveGroupId,
    getUserDetails,
    getKpaManagedEditedUserRoles,
    getCurrentUserId,
  ],
  (
    kpaManagedUserGroups,
    activeRemoveGroupId,
    userDetails,
    kpaManagedEditedUserRoles,
    currentUserId
  ) => {
    if (!userDetails?.id) return [];

    const userId = userDetails.id;

    const filteredGroupByRemoveId = kpaManagedUserGroups.reduce<
      IAssignedRole[]
    >((acc, item) => {
      if (item.id === activeRemoveGroupId) {
        acc.push(...item.assignedRoles);
      }
      return acc;
    }, []);

    const matchAssignedRole = filteredGroupByRemoveId.filter(
      (group) =>
        !kpaManagedEditedUserRoles.some(
          (role) => role.role === group.roleDisplayName
        )
    );

    if (userId === currentUserId) {
      return matchAssignedRole;
    } else return [];
  }
);

export const getKpaManagedProductsAndRolesOptionsForRolesDrawer =
  createSelector(
    [
      getUnassignedProductsAndRolesOptions,
      getKpaManagedInstancesOptionsForRolesDrawer,
    ],
    (productAndRoles, instancesOptionsDictionary) => {
      const kpaManagedProductsNames = Object.keys(instancesOptionsDictionary);

      return productAndRoles.filter((option) =>
        kpaManagedProductsNames.includes(option.label)
      );
    }
  );

/* ----------------- Invite Stepper ----------------- */
export const getUsersStepperStep = createSelector(
  [getState],
  ({ usersStepperStep }) => usersStepperStep
);
export const getIsPendingToCreateUsers = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.isPendingToCreateUsers
);
export const getInviteStepperCurrentStep = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.currentStep
);
export const getCreateUsersNotification = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.createUsersNotification
);
export const getInvitedUsersCount = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.createUsersResponse.invitedUsersCount
);
export const getIsSuccessInviteUsersNotificationVisible = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.isSuccessInviteUsersNotificationVisible
);

export const getFormFieldsForNewGroup = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.newGroup
);

export const getIsUserDetailsDirty = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.isUserDetailsDirty
);

export const getStepperInviteUsersMethod = createSelector(
  [getState],
  ({ inviteStepper }) => inviteStepper.inviteUsersMethod
);

/* ----------------- Invite Stepper ----------------- */

export const getUserSearchValue = createSelector(
  [getState],
  ({ userSearchValue }) => userSearchValue
);
